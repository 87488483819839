import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
// import './index.css';
// import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import "app/globals.css";
import 'moment/locale/fr';
import moment from 'moment';

import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
// ROUTER
import ScrollToTop from 'ScrollTop';
import Accueil, {accueilLoader} from './app/Accueil';
import AboutUs from 'app/AboutUs';
import SingleEvent from 'app/events/single/SingleEvent';
import Login from 'app/login/Login';
// event manage
import AdminContainer from 'app/components/AdminContainer';
import EventGenerate from 'app/events/generate/EventGenerate';
import EventList from 'app/events/list/EventList';
import EventsByCategory from 'app/events/category/[category]/EventsByCategory';
import EventListSingle, { EventListSingleLoader } from 'app/events/list/single/EventListSingle';
// Payments
import PaymentSuccess from 'app/payments/success/PaymentSuccess';
import PaymentError from 'app/payments/error/PaymentError';
// User
import UserTickets from 'app/user/tickets/UserTickets';
// Locations
import Locations from 'app/events/locations/Locations';
// Transportations
import Transporations from 'app/events/transportations/Transportations';
import MessageList from 'app/messages/MessageList';
import Profile from 'app/user/Profile';

//import "slick-carousel/slick/slick.css";
//import "slick-carousel/slick/slick-theme.css";

moment.locale('fr', {
  months : 'janvier_février_mars_avril_mai_juin_juillet_août_septembre_octobre_novembre_décembre'.split('_'),
  monthsShort : 'janv._févr._mars_avr._mai_juin_juil._août_sept._oct._nov._déc.'.split('_'),
  monthsParseExact : true,
  weekdays : 'dimanche_lundi_mardi_mercredi_jeudi_vendredi_samedi'.split('_'),
  weekdaysShort : 'dim._lun._mar._mer._jeu._ven._sam.'.split('_'),
  weekdaysMin : 'Di_Lu_Ma_Me_Je_Ve_Sa'.split('_'),
  weekdaysParseExact : true,
  longDateFormat : {
      LT : 'HH:mm',
      LTS : 'HH:mm:ss',
      L : 'DD/MM/YYYY',
      LL : 'D MMMM YYYY',
      LLL : 'D MMMM YYYY HH:mm',
      LLLL : 'dddd D MMMM YYYY HH:mm'
  },
  calendar : {
      sameDay : '[Aujourd’hui à] LT',
      nextDay : '[Demain à] LT',
      nextWeek : 'dddd [à] LT',
      lastDay : '[Hier à] LT',
      lastWeek : 'dddd [dernier à] LT',
      sameElse : 'L'
  },
  relativeTime : {
      future : 'dans %s',
      past : 'il y a %s',
      s : 'quelques secondes',
      m : 'une minute',
      mm : '%d minutes',
      h : 'une heure',
      hh : '%d heures',
      d : 'un jour',
      dd : '%d jours',
      M : 'un mois',
      MM : '%d mois',
      y : 'un an',
      yy : '%d ans'
  },
  dayOfMonthOrdinalParse : /\d{1,2}(er|e)/,
  ordinal : function (number) {
      return number + (number === 1 ? 'er' : 'e');
  },
  meridiemParse : /PD|MD/,
  isPM : function (input) {
      return input.charAt(0) === 'M';
  },
  // In case the meridiem units are not separated around 12, then implement
  // this function (look at locale/id.js for an example).
  // meridiemHour : function (hour, meridiem) {
  //     return /* 0-23 hour, given meridiem token and hour 1-12 */ ;
  // },
  meridiem : function (hours, minutes, isLower) {
      return hours < 12 ? 'PD' : 'MD';
  },
  week : {
      dow : 1, // Monday is the first day of the week.
      doy : 4  // Used to determine first week of the year.
  }
});

const router = createBrowserRouter([
  {
    path: "/",
    element: <ScrollToTop/>,
    children:[
      {
        path:"/login",
        element: <Login/>
      },
      {
        path: "/",
        element: <Accueil/>,
        loader: accueilLoader
      },              
      {
        path: "/a-propos",
        element: <AboutUs/>
      },              
      {
        path: "/events/category/:id",
        element: <EventsByCategory />
      },
      // EVENTS MANAGER
      {
        path: "/admin",
        element:<AdminContainer/> ,
        children:[
          {
            path: "events/generate",
            element: <EventGenerate />
          },
          {
            path: "events/list",
            element: <EventList/>
          },
          {
            path: "events/list/:id",
            element: <EventListSingle/>,
            loader: EventListSingleLoader
          },
          // LOCATIONS
          {
            path: "locations",
            element: <Locations/>
          },
          // TRANPORTS
          {
            path: "transportations",
            element: <Transporations/>
          }
        ]
      },
      //USERS
      {
        path: "/user",
        element:<AdminContainer/> ,
        children:[
          {
            path: "profile",
            element: <Profile/>
          },
          {
            path: "events",
            element: <EventList filterCurrentUser={true}/>
          },
          {
            path: "messages",
            element: <MessageList filterCurrentUser={true}/>
          },
          {
            path: "tickets",
          // element: <EventListSingle filterCurrentUser={true}/>
          element: <UserTickets/>
          }
        ]
    },
      // SINGLE EVENT
      {
        path:"/events/:id",
        element: <SingleEvent />
      },
      // PAYMENT
      {
        path: '/payments/success',
        element: <PaymentSuccess />
      },
      {
        path: '/payments/error',
        element: <PaymentError />
      },
      
    ]
  }
]);

// RENDER
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RecoilRoot>
      <RouterProvider
        router={router}
        //fallbackElement={<div>Routeur en chargement ...</div>}
      />
    </RecoilRoot>
  </React.StrictMode>
);

if('serviceWorker' in navigator) {
  navigator.serviceWorker.register('./sw.js').then((res)=>{
    console.log('SW REGISTERED');
  })
} else {
  console.log("NO SERVICE WORKER");
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
